import React from "react";
import "./Resume.css";
import DesktopView from "../Components/DesktopView/DesktopView";

const Resume = () => {
  return (
    <div>
      <DesktopView />
    </div>
  );
};

export default Resume;
